exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-about-page-template-jsx": () => import("./../../../src/templates/AboutPageTemplate.jsx" /* webpackChunkName: "component---src-templates-about-page-template-jsx" */),
  "component---src-templates-contacts-page-template-jsx": () => import("./../../../src/templates/ContactsPageTemplate.jsx" /* webpackChunkName: "component---src-templates-contacts-page-template-jsx" */),
  "component---src-templates-crypto-page-template-jsx": () => import("./../../../src/templates/CryptoPageTemplate.jsx" /* webpackChunkName: "component---src-templates-crypto-page-template-jsx" */),
  "component---src-templates-home-page-template-jsx": () => import("./../../../src/templates/HomePageTemplate.jsx" /* webpackChunkName: "component---src-templates-home-page-template-jsx" */),
  "component---src-templates-maintenance-page-template-jsx": () => import("./../../../src/templates/MaintenancePageTemplate.jsx" /* webpackChunkName: "component---src-templates-maintenance-page-template-jsx" */),
  "component---src-templates-mobile-apps-page-template-jsx": () => import("./../../../src/templates/MobileAppsPageTemplate.jsx" /* webpackChunkName: "component---src-templates-mobile-apps-page-template-jsx" */),
  "component---src-templates-mockup-page-template-jsx": () => import("./../../../src/templates/MockupPageTemplate.jsx" /* webpackChunkName: "component---src-templates-mockup-page-template-jsx" */),
  "component---src-templates-portfolio-page-template-jsx": () => import("./../../../src/templates/PortfolioPageTemplate.jsx" /* webpackChunkName: "component---src-templates-portfolio-page-template-jsx" */),
  "component---src-templates-portfolio-post-template-jsx": () => import("./../../../src/templates/PortfolioPostTemplate.jsx" /* webpackChunkName: "component---src-templates-portfolio-post-template-jsx" */),
  "component---src-templates-privacy-policy-template-jsx": () => import("./../../../src/templates/PrivacyPolicyTemplate.jsx" /* webpackChunkName: "component---src-templates-privacy-policy-template-jsx" */),
  "component---src-templates-service-page-template-jsx": () => import("./../../../src/templates/ServicePageTemplate.jsx" /* webpackChunkName: "component---src-templates-service-page-template-jsx" */),
  "component---src-templates-terms-of-services-template-jsx": () => import("./../../../src/templates/TermsOfServicesTemplate.jsx" /* webpackChunkName: "component---src-templates-terms-of-services-template-jsx" */),
  "component---src-templates-why-us-page-template-jsx": () => import("./../../../src/templates/WhyUsPageTemplate.jsx" /* webpackChunkName: "component---src-templates-why-us-page-template-jsx" */)
}

