import React from "react";
import { Provider } from "react-redux";
import { createStore as reduxCreateStore } from "redux";
import rootReducer from ".";
import FacebookMsg from "../components/FacebookMsg/Facebook";

const createStore = () => reduxCreateStore(rootReducer);

const init = ({ element }) => (
  <>
    <Provider store={createStore()}>{element}</Provider>
    <FacebookMsg />
  </>
);

export default init;
