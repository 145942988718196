const initialState = {
  isShowingCalendly: false,
  isShowingContactForm: false,
};

const SHOW_CALENDLY = "SHOW_CALENDLY";
const SHOW_CONTACT_FORM = "SHOW_CONTACT_FORM";

const app = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_CALENDLY:
      return { ...state, isShowingCalendly: action.showCalendly };
    case SHOW_CONTACT_FORM:
      return { ...state, isShowingContactForm: action.showContactForm };
    default:
      return state;
  }
};

export const showCalendly = (showCalendly) => ({
  type: SHOW_CALENDLY,
  showCalendly,
});
export const showContactForm = (showContactForm) => ({
  type: SHOW_CONTACT_FORM,
  showContactForm,
});
export default app;
